import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../assets/HeroImage';
import SvgCharts from '../assets/SvgCharts';
import SoftwareEngineer from '../assets/SoftwareEngineer';
import OpenSource from '../assets/OpenSource';
import Teacher from '../assets/Teacher';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Index = () => (
  <Layout>
    <section className="pt-20 md:py-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-2/5 lg:pr-20 mb-2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none ">
            Medical Imaging Revisited
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Delivering state-of-the-art solutions for medical imaging problems
          </p>
          <p className="mt-8 md:mt-12">
            <AnchorLink href="#contact">
              <Button size="lg:h-12 md:h-10 sm:h-8 h=4">
                Get Started
              </Button>
            </AnchorLink>
          </p>
        </div>
        <div className="lg:w-3/5 lg:mt-0 sm:mt-10">
          <HeroImage />
        </div>
      </div>
    </section>
    {/* <section id="features" className="lg:pb-5 lg:pt-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Services</h2>
      </div>
    </section> */}
    <section className="container mx-auto my-10 py-4 bg-gray-200 rounded-lg text-center">
    </section>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Consultation Services</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Over 10 years of research and development experience in AI and medical imaging

          </p>
        </div>
      }
      secondarySlot={<SoftwareEngineer />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Lectures
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            More than 4 years of teaching experience in medical imaging and machine learning
          </p>
        </div>
      }
      secondarySlot={<Teacher />}
    />
    {/* <SoftwareEngineer /> */}
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Open-source Contribution
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We contribute actively to our beloved open source community and maintain medical imaging libraries</p>
        </div>
      }
      secondarySlot={<OpenSource />}
    />
    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}
    {/* <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    <section id="contact" className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to have a chat?</h3>
      <p className="mt-8">
        <a href={`mailto:info@accoladeImaging.com`}>
          <Button size="xl">Get Started Now</Button>
        </a>
      </p>
    </section>
  </Layout>
);

export default Index;
