import React from 'react'

const mainColor = '#4299e1'

const OpenSource = () => (
  <svg
    id="a58ef234-55dd-4456-b368-278f056cc5c7"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1113.58 784.41"

  >
    <title>open source</title>
    <circle cx="332.00497" cy="292.00357" r="292.00357" fill="#f2f2f2" />
    <path
      d="M1114,668.4987a13.68982,13.68982,0,0,1-.16992,2.12c-7.14014,43.76-234.46008,78.88-513.83008,78.88s-506.69-35.12-513.83-78.88a13.67509,13.67509,0,0,1-.17-2.12c0-.2,0-.41.02-.61,0-.11005.01-.21.01-.32a.48944.48944,0,0,0,.01-.12c.04-.45.1-.88.18-1.32,3.26-17.89,43.35-34.33,108.74-47.5,53.48-10.77,123.89-19.36,204.93-24.76,61.5-4.1,129.12-6.37,200.11-6.37,57.12,0,112.06,1.47,163.38,4.17h.02q16.11.855,31.74,1.87c71.33,4.62,134.63,11.69,185.63,20.54,31.37,5.45,58.1,11.58,79.18994,18.22h.01c32.46,10.23,51.6001,21.7,53.81006,33.83.08.44.13989.87.17993,1.32a.48659.48659,0,0,0,.01.12c0,.11.01.21.01.32C1114,668.08867,1114,668.29869,1114,668.4987Z"
      transform="translate(-86 -150.5013)"
      fill="#3f3d56"
    />
    <ellipse cx="501" cy="571.99741" rx="165" ry="24" opacity="0.1" />
    <path
      d="M1059.96,632.29869c-5.77,1.65-12.27,3.18-19.3999,4.58-29.36011,5.78-69.42005,9.33-113.53,9.33-49.28,0-93.45-4.43-123.38-11.44-24.84-5.81994-39.88-13.38995-39.88-21.69995,0-7.30005,11.63995-14.06,31.37-19.53,71.33,4.62,134.63,11.69,185.63,20.54C1012.14,619.52867,1038.87,625.65868,1059.96,632.29869Z"
      transform="translate(-86 -150.5013)"
      opacity="0.1"
    />
    <path
      d="M618,621.2487c0,15.13-94.92,27.4-212,27.4s-212-12.27-212-27.4a4.372,4.372,0,0,1,.96-2.62c53.48-10.77,123.89-19.36,204.93-24.76q3.045-.015,6.11-.01C523.08,593.85869,618,606.1187,618,621.2487Z"
      transform="translate(-86 -150.5013)"
      opacity="0.1"
    />
    <rect
      x="416.47191"
      y="334.97493"
      width="41.68539"
      height="134.58427"
      fill="#4099e1"
    />
    <rect
      x="279.50562"
      y="271.85134"
      width="41.68539"
      height="197.70787"
      fill="#4099e1"
    />
    <rect
      x="143.73034"
      y="271.85134"
      width="41.68539"
      height="197.70787"
      fill="#4099e1"
    />
    <rect
      x="211.61798"
      y="271.85134"
      width="41.68539"
      height="134.58427"
      fill="#4099e1"
    />
    <rect
      x="347.39326"
      y="271.85134"
      width="41.68539"
      height="39.30337"
      fill="#4099e1"
    />
    <rect
      x="347.39326"
      y="334.97493"
      width="41.68539"
      height="134.58427"
      fill="#8a8b8c"
    />
    <rect
      x="416.47191"
      y="271.85134"
      width="109.57303"
      height="39.30337"
      fill="#4099e1"
    />
    <path
      d="M901.062,410.53031q42.74823,0,72.77372,29.83923A97.98265,97.98265,0,0,1,995.66753,473.208a107.34579,107.34579,0,0,1,.09551,78.40916,93.53335,93.53335,0,0,1-21.73946,32.294,104.70367,104.70367,0,0,1-33.84136,22.5609,101.42451,101.42451,0,0,1-39.11384,7.82277,99.01466,99.01466,0,0,1-38.66173-7.73362A104.84511,104.84511,0,0,1,807.00731,551.165a101.7643,101.7643,0,0,1,.08913-77.59409A102.30668,102.30668,0,0,1,829.565,440.18488Q858.67354,410.53668,901.062,410.53031Zm.36932,18.3773q-34.93023,0-58.76787,24.37889A87.59486,87.59486,0,0,0,824.197,480.66778a80.78885,80.78885,0,0,0,0,63.31126,86.24851,86.24851,0,0,0,45.5771,45.30965,82.68682,82.68682,0,0,0,63.41-.08914,87.99456,87.99456,0,0,0,27.74106-18.37412q23.83128-23.28683,23.82809-58.40491a83.65859,83.65859,0,0,0-6.18627-32.02018,81.465,81.465,0,0,0-18.0016-26.92917Q935.98906,428.9092,901.43132,428.90761Zm-1.27673,66.59064-13.64925,7.09685a14.0061,14.0061,0,0,0-5.36482-6.36774,12.08742,12.08742,0,0,0-5.91563-1.818q-13.63969,0-13.64606,18.01115,0,8.18574,3.45449,13.09526,3.45768,4.9143,10.19157,4.91589,8.91644,0,12.55719-8.73335l12.55082,6.36774a29.99,29.99,0,0,1-26.74451,16.01168q-13.64448,0-22.01646-8.3704-8.36721-8.36721-8.36721-23.28364,0-14.55666,8.45954-23.10535,8.45795-8.5487,21.37969-8.55187Q891.97045,480.75694,900.15459,495.49825Zm58.76469,0-13.46459,7.09685a13.99175,13.99175,0,0,0-5.368-6.36774,12.37253,12.37253,0,0,0-6.09393-1.818q-13.64446,0-13.64924,18.01115,0,8.18574,3.45767,13.09526,3.45291,4.9143,10.19157,4.91589,8.90688,0,12.54764-8.73335l12.73548,6.36774a31.27658,31.27658,0,0,1-11.27409,11.73575,29.39766,29.39766,0,0,1-15.46405,4.27593q-13.83072,0-22.09923-8.3704-8.2908-8.36721-8.28444-23.28364,0-14.55666,8.46273-23.10535,8.45319-8.5487,21.37333-8.55187Q950.91184,480.75694,958.91928,495.49825Z"
      transform="translate(-86 -150.5013)"
      fill="#4099e1"
    />
    <path
      d="M839.41688,660.83685c0,48.73748-67.93425,33.66253-151.11288,33.66253s-150.10359,15.075-150.10359-33.66253,67.925-59.33747,151.10359-59.33747S839.41688,612.09937,839.41688,660.83685Z"
      transform="translate(-86 -150.5013)"
      opacity="0.1"
    />
    <path
      d="M839.41688,648.83685c0,48.73748-67.93425,33.66253-151.11288,33.66253s-150.10359,15.075-150.10359-33.66253,67.925-59.33747,151.10359-59.33747S839.41688,600.09937,839.41688,648.83685Z"
      transform="translate(-86 -150.5013)"
      fill="#3f3d56"
    />
    <path
      d="M825.54156,561.34329l3.43359,14.16355a.88462.88462,0,0,1-.01719.478l-15.57547,48.67335a.88465.88465,0,0,1-1.72156-.16974l-2.15469-18.96122a.88471.88471,0,0,1,.03787-.374l14.29657-43.87567A.88465.88465,0,0,1,825.54156,561.34329Z"
      transform="translate(-86 -150.5013)"
      fill="#2f2e41"
    />
    <polygon
      points="738.278 412.038 741.449 424.19 727.711 468.572 724.541 454.835 738.278 412.038"
      fill="#f2f2f2"
    />
    <path
      d="M755.06392,628.58381c.52836,1.58507,54.949-.52835,55.47732-1.05671a11.4687,11.4687,0,0,0,1.16235-2.11342c.49669-1.05671.95107-2.11342.95107-2.11342l-2.11342-17.10815L756.649,604.27946s-1.34734,14.49808-1.62209,21.13422A15.85321,15.85321,0,0,0,755.06392,628.58381Z"
      transform="translate(-86 -150.5013)"
      fill="#2f2e41"
    />
    <polygon
      points="717.673 458.005 718.729 472.799 694.953 472.799 694.953 458.005 717.673 458.005"
      opacity="0.1"
    />
    <polygon
      points="687.028 462.76 687.17 462.732 686.5 469.1 673.291 469.1 673.291 462.76 687.028 462.76"
      opacity="0.1"
    />
    <path
      d="M755.06392,628.58381c.52836,1.58507,54.949-.52835,55.47732-1.05671a11.4687,11.4687,0,0,0,1.16235-2.11342H755.0269A15.85321,15.85321,0,0,0,755.06392,628.58381Z"
      transform="translate(-86 -150.5013)"
      opacity="0.1"
    />
    <path
      d="M671.05541,573.10649h14.79395l36.45652-14.26559s25.88942-10.56711,23.776,9.5104S740.27,615.37493,740.27,615.37493s-11.62382-5.28356-17.96409-3.69849-2.11342-32.22968-2.11342-32.22968-51.77883,24.8327-56.534,21.66257-5.81191-25.36106-5.81191-25.36106Z"
      transform="translate(-86 -150.5013)"
      fill="#2f2e41"
    />
    <path
      d="M671.05541,573.10649h14.79395l36.45652-14.26559s25.88942-10.56711,23.776,9.5104S740.27,615.37493,740.27,615.37493s-11.62382-5.28356-17.96409-3.69849-2.11342-32.22968-2.11342-32.22968-51.77883,24.8327-56.534,21.66257-5.81191-25.36106-5.81191-25.36106Z"
      transform="translate(-86 -150.5013)"
      opacity="0.1"
    />
    <path
      d="M694.36337,526.77716l17.90376,25.72347,41.74008,34.87146s38.56994,8.982,33.28639,15.32231-37.51323-6.34027-37.51323-6.34027S701.7,561.48268,700.115,558.8409s-19.0208-27.47448-19.0208-27.47448Z"
      transform="translate(-86 -150.5013)"
      fill="#a0616a"
    />
    <path
      d="M694.36337,526.77716l17.90376,25.72347,41.74008,34.87146s38.56994,8.982,33.28639,15.32231-37.51323-6.34027-37.51323-6.34027S701.7,561.48268,700.115,558.8409s-19.0208-27.47448-19.0208-27.47448Z"
      transform="translate(-86 -150.5013)"
      opacity="0.1"
    />
    <circle cx="605.66127" cy="324.33109" r="23.77599" fill="#a0616a" />
    <path
      d="M690.0762,495.9666s-7.92533,13.73724-9.51039,21.13422-23.776-14.79395-23.776-14.79395l-2.90595-7.66115s20.87-9.77458,19.285-17.69991S690.0762,495.9666,690.0762,495.9666Z"
      transform="translate(-86 -150.5013)"
      fill="#a0616a"
    />
    <path
      d="M683.73594,506.00536l11.62382,22.71928s-2.64178,11.09546-6.86862,12.15217-22.19093-13.73724-22.19093-13.73724Z"
      transform="translate(-86 -150.5013)"
      fill="#d0cde1"
    />
    <path
      d="M694.30305,537.17833l21.66257,28.53119,51.77883,38.56994s37.51323,7.92534,29.05955,13.73724-32.758-5.28355-32.758-5.28355-52.30718-28.00284-63.40265-39.62666-31.70132-31.173-31.70132-31.173Z"
      transform="translate(-86 -150.5013)"
      fill="#a0616a"
    />
    <path
      d="M665.2435,581.56018l6.34026,10.03875,37.83932-11.9045a29.75135,29.75135,0,0,1,24.7708,2.98851c6.60444,4.1608,10.83129,10.76524,1.32089,20.53981-19.0208,19.54915-31.70133,8.982-31.70133,8.982s-63.931,30.11625-74.49811,15.3223-11.09546-22.19093-11.09546-22.19093S658.90323,579.97511,665.2435,581.56018Z"
      transform="translate(-86 -150.5013)"
      fill="#2f2e41"
    />
    <path
      d="M744.49681,616.43164s16.90737,11.09546-3.17013,16.379-34.87146-2.11343-34.87146-2.11343-17.43573,0-17.43573-8.982,5.81191-10.03875,5.81191-10.03875l17.96409,2.64178S734.45806,608.50631,744.49681,616.43164Z"
      transform="translate(-86 -150.5013)"
      fill="#d0cde1"
    />
    <path
      d="M711.16583,468.8514a17.728,17.728,0,0,0,4.98235,1.78469,4.548,4.548,0,0,0,4.59533-1.96886,6.18073,6.18073,0,0,0,.522-2.7952c.06365-2.90132-.14619-6.03778-1.98475-8.28309-1.1701-1.429-2.87816-2.32977-4.08936-3.72406a17.59821,17.59821,0,0,1-2.06717-3.37767c-2.35552-4.42406-5.84318-8.67589-10.69025-9.9513a23.18347,23.18347,0,0,0-6.24-.47707l-12.20625.17221a21.25081,21.25081,0,0,0-5.872.632c-3.92885,1.1879-6.78945,4.49179-9.40936,7.65139a47.85117,47.85117,0,0,0-5.39639,7.44763,26.133,26.133,0,0,0-3.09771,12.80437,11.97989,11.97989,0,0,0,.43881,3.38808,18.80129,18.80129,0,0,0,1.33168,2.925c2.28907,4.49613,3.64824,10.1121,1.07032,14.44908,4.47812-1.82926,8.90892-4.22774,11.863-8.05834,1.3215-1.71364,2.35787-3.7197,4.119-4.9772s4.63076-1.36658,5.76072.479a5.163,5.163,0,0,1,.58258,2.41835,11.084,11.084,0,0,0,.66762,4.08133,3.05833,3.05833,0,0,0,3.32224,1.8956c2.30039-.648,2.0801-4.17861,3.85194-5.78243,1.3456-1.218,3.42157-.98516,5.15028-1.53807a7.64334,7.64334,0,0,0,4.38653-4.32052c.65453-1.46807.64508-5.3098,1.88365-6.10481C706.19386,466.62453,709.72609,468.20958,711.16583,468.8514Z"
      transform="translate(-86 -150.5013)"
      fill="#2f2e41"
    />
    <path
      d="M651.99646,492.13947a6.27057,6.27057,0,0,1,3.26389.00831c3.39162.91054,10.99473,3.321,12.62493,6.989,2.11342,4.7552,9.51039,12.15217,9.51039,12.15217s10.03876,10.03875,7.92534,16.90737-10.03876,14.794-10.03876,14.794,2.11342,32.758-8.45368,41.21172-14.794,2.64178-14.794,10.56711-32.758,32.758-39.0983,17.43573c0,0,3.17014-28.5312,2.11343-38.56995C614.00826,563.74087,615.53292,502.01108,651.99646,492.13947Z"
      transform="translate(-86 -150.5013)"
      fill="#d0cde1"
    />
    <path
      d="M668.942,505.477s32.22968,23.776,26.41777,33.81475c0,0-19.54915,9.5104-23.776,8.982s-20.60586-19.02079-24.8327-20.60586S640.4108,497.55167,668.942,505.477Z"
      transform="translate(-86 -150.5013)"
      fill="#d0cde1"
    />
    <path
      d="M579.81065,526.78274c-5.01266,1.69284-9.5539,5.65228-10.58039,10.84253-.47582,2.40592-.18409,4.907-.58451,7.32661-.92865,5.61161-5.33358,9.89722-9.39638,13.878s-8.2584,8.60617-8.55492,14.28636c-.3247,6.22,4.1862,12.047,3.40563,18.22633-.90687,7.17909-8.48947,11.831-10.36389,18.82016-1.25164,4.667.28211,9.6942,2.8055,13.81484,4.80858,7.85231,13.0279,13.18323,21.78085,16.041s18.07506,3.45905,27.28222,3.55552c11.21079.11746,23.38277-.845,31.806-8.244a25.48043,25.48043,0,0,0,8.459-16.5882c.8002-8.57064-2.77695-17.42641-.30951-25.67311,1.55287-5.19,5.44709-9.93,4.86592-15.316-.56823-5.26625-5.16773-8.99907-8.32312-13.25346-2.9461-3.97222-4.7338-8.66319-6.48587-13.28794l-5.99481-15.82383c-1.17848-3.1107-2.47257-6.38232-5.09885-8.424-4.91665-3.8221-12.82242-3.07115-18.57152-2.28285C590.61855,525.412,584.93966,525.0506,579.81065,526.78274Z"
      transform="translate(-86 -150.5013)"
      fill="#2f2e41"
    />
    <path
      d="M581.68806,572.87747s2.65748,8.63681-17.27362,18.60236,1.99311,20.59548,1.99311,20.59548l23.25295,5.315s23.91732-3.32185,26.5748-7.30808,7.30808-17.27362,5.97934-19.26673a31.0614,31.0614,0,0,0-3.32186-3.98622s-15.94488-2.65748-15.28051-9.96555S581.68806,572.87747,581.68806,572.87747Z"
      transform="translate(-86 -150.5013)"
      fill="#ffb9b9"
    />
    <path
      d="M581.68806,572.87747s2.65748,8.63681-17.27362,18.60236,1.99311,20.59548,1.99311,20.59548l23.25295,5.315s23.91732-3.32185,26.5748-7.30808,7.30808-17.27362,5.97934-19.26673a31.0614,31.0614,0,0,0-3.32186-3.98622s-15.94488-2.65748-15.28051-9.96555S581.68806,572.87747,581.68806,572.87747Z"
      transform="translate(-86 -150.5013)"
      opacity="0.1"
    />
    <circle cx="508.64328" cy="414.73592" r="21.25984" fill="#ffb9b9" />
    <path
      d="M592.318,610.08219s-25.60367-1.87245-26.08923-19.53858c0,0-9.12238-1.05689-11.77986,1.60059s-11.2943,4.65059-11.2943,11.29429,14.61615,26.57481,14.61615,26.57481,7.97244,26.5748,4.65059,31.22539-3.98622,16.60925-3.98622,16.60925,33.2185-2.65748,38.53346,0,26.57481,2.65748,27.90355,0-1.32874-35.876-1.32874-35.876,5.97933-7.97244,4.65059-17.27362L644.13885,606.096s-5.97933-17.938-15.28051-17.938c0,0-12.623-3.32185-12.95522-1.66092S622.21464,606.76034,592.318,610.08219Z"
      transform="translate(-86 -150.5013)"
      fill="#ff6584"
    />
    <path
      d="M532.52467,668.54676s5.97933,9.30118,21.25985,11.29429,17.938-3.32185,16.60925-4.65059-17.27362-7.30807-17.27362-7.30807l-9.30119-7.30807Z"
      transform="translate(-86 -150.5013)"
      fill="#ffb9b9"
    />
    <path
      d="M641.48137,660.57432l-17.27362,9.96555s-22.58859-1.32874-13.95178,5.315,24.5817,3.98622,24.5817,3.98622l15.28051-7.30807Z"
      transform="translate(-86 -150.5013)"
      fill="#ffb9b9"
    />
    <path
      d="M555.11326,677.84794s-15.94489,39.19784,3.32185,45.17717,72.41634,4.65059,84.375-3.32185,15.28051-18.60236,13.2874-21.92421-15.28051-12.623-24.58169-13.2874S555.11326,677.84794,555.11326,677.84794Z"
      transform="translate(-86 -150.5013)"
      fill="#2f2e41"
    />
    <path
      d="M555.11326,677.84794s-15.94489,39.19784,3.32185,45.17717,72.41634,4.65059,84.375-3.32185,15.28051-18.60236,13.2874-21.92421-15.28051-12.623-24.58169-13.2874S555.11326,677.84794,555.11326,677.84794Z"
      transform="translate(-86 -150.5013)"
      opacity="0.05"
    />
    <path
      d="M630.37766,680.28318s56.28087-20.37323,55.6165,4.20847-35.876,31.22539-35.876,31.22539l-10.62992,1.32874-58.46457,1.99311s1.99311-10.62992-2.65748-11.95866c0,0,15.28051-3.98622,15.94488-4.65059s38.53347-13.95177,41.19095-14.61614,10.62992-5.97934,16.60925-5.315c0,0,6.6437-5.315,13.95177-3.98622l-20.59547,1.32874Z"
      transform="translate(-86 -150.5013)"
      fill="#2f2e41"
    />
    <path
      d="M630.37766,680.28318s56.28087-20.37323,55.6165,4.20847-35.876,31.22539-35.876,31.22539l-10.62992,1.32874-58.46457,1.99311s1.99311-10.62992-2.65748-11.95866c0,0,15.28051-3.98622,15.94488-4.65059s38.53347-13.95177,41.19095-14.61614,10.62992-5.97934,16.60925-5.315c0,0,6.6437-5.315,13.95177-3.98622l-20.59547,1.32874Z"
      transform="translate(-86 -150.5013)"
      opacity="0.1"
    />
    <polygon
      points="534.434 533.081 541.53 549.271 584.714 533.99 588.036 525.354 576.741 523.36 554.035 527.347 544.187 530.004 534.434 533.081"
      fill="#2f2e41"
    />
    <polygon
      points="534.434 533.081 541.53 549.271 584.714 533.99 588.036 525.354 576.741 523.36 554.035 527.347 544.187 530.004 534.434 533.081"
      opacity="0.1"
    />
    <path
      d="M528.53845,703.094s12.623,15.94488,27.23918,19.9311,22.58858-1.32874,22.58858-1.32874L573.71562,703.094l-1.99311-11.95866L563.0857,685.156l-17.27363-7.97245-20.59547-6.6437s-8.63681-1.32874-8.63681,0,7.97244,21.25985,7.97244,21.25985Z"
      transform="translate(-86 -150.5013)"
      fill="#2f2e41"
    />
    <path
      d="M543.819,711.73082s2.65748,19.26673,5.97934,20.59547,13.2874-1.32874,15.94488-2.65748,11.69054-7.45919,11.69054-7.45919S545.81207,710.40208,543.819,711.73082Z"
      transform="translate(-86 -150.5013)"
      fill="#f2f2f2"
    />
    <polygon
      points="518.277 563.223 530.9 565.216 529.571 580.496 511.964 576.22 518.277 563.223"
      fill="#ffb9b9"
    />
    <path
      d="M612.18567,717.97655s-5.91592,7.04167.06341,11.02789l3.98622,3.32185s13.28741,2.65748,14.61615,3.98622,6.6437-1.99311,6.6437-1.99311.66437-19.9311,0-21.25984-8.63681-2.65748-9.30118-1.32874-8.63682,3.32185-13.28741,1.99311Z"
      transform="translate(-86 -150.5013)"
      fill="#f2f2f2"
    />
    <path
      d="M559.09948,682.49853s-46.50591-27.23917-53.814-17.27362-15.28051,23.91733,5.97933,34.54725,87.69685,32.55413,87.69685,32.55413,9.96555-15.28051,9.96555-19.26673c0,0-35.21161-15.94488-41.19094-17.27362s-29.89666-16.60926-33.21851-17.938-7.97244-5.315-7.97244-5.315Z"
      transform="translate(-86 -150.5013)"
      fill="#2f2e41"
    />
    <path
      d="M545.81207,600.11664l-2.65748,1.99311s-5.97933,15.28052-6.6437,23.91733-8.63681,35.21161-7.97244,38.53346,1.32874,5.97933,5.315,9.30118c0,0,5.315-11.95866,14.61615-9.96555l11.29429-41.19094Z"
      transform="translate(-86 -150.5013)"
      fill="#ff6584"
    />
    <path
      d="M638.82389,600.11664l5.315,5.97933s3.32185,7.97245,3.32185,9.96556,6.6437,31.22539,7.30807,32.55413,3.32185,17.938,0,21.92421-9.30118,6.6437-9.30118,6.6437,3.98622-13.95177-8.63681-14.61614l1.32874-5.315-11.95866-39.8622Z"
      transform="translate(-86 -150.5013)"
      fill="#ff6584"
    />
    <path
      d="M547.14082,645.85762v34.9949a8.41342,8.41342,0,0,0,8.2888,8.41251l73.18511,1.08423a8.41344,8.41344,0,0,0,8.53185-8.08916l1.34611-34.999a8.41344,8.41344,0,0,0-8.28529-8.73591l-74.53123-1.08016A8.41342,8.41342,0,0,0,547.14082,645.85762Z"
      transform="translate(-86 -150.5013)"
      fill="#3f3d56"
    />
    <ellipse
      cx="509.89011"
      cy="401.732"
      rx="22.88412"
      ry="12.32222"
      fill="#2f2e41"
    />
    <circle cx="505.9858" cy="513.72706" r="5.31496" fill="#f2f2f2" />
  </svg>
)
export default OpenSource
