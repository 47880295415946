import React from 'react';
import video from './file.mp4'
import { StaticImage } from "gatsby-plugin-image"


const HeroImage = () => {
  // return <StaticImage src="./hero.png" alt="A dinosaur" />
  return (
    <video autoPlay muted loop>
      <source src={video} type="video/mp4" />
    </video>
  )

}


export default HeroImage;
